/** Colors */
$blue-primary: #4285F4;
$red-primary: #DB4437;
$yellow-primary: #F4B400;
$green-primary: #0F9D58;
$green-progress: #34A853;
$border-primary: #D8D8D8;
$border-radius-card: 8px;
$border-radius-controls: 4px;
$black-secondary: #5F6367;
$text: #202124;
$gray-background: #F8F9FA;

/** app constanst */
$width-md: 80%;

$breakpoints: (
  sm : 540px,
  md : 768px,
  lg : 1024px
);

/** Media querys */
@mixin media-up($breakpoint) {
  @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin media-down($breakpoint) {
  @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}
