@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "./styles/base/variables";
@import "./styles/base/base";
@import "./styles/base/reset";
/** Components */
@import "./app/shared/header/header.component.scss";
@import "./app/components/google-sign-in/google-sign-in.component.scss";
/** Pages */
@import "./app/pages/pages.component.scss";
